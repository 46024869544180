import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'layouts';

import GridContainer from '../components/Grid/GridContainer.jsx';
import GridItem from '../components/Grid/GridItem.jsx';
import useLocalStorage from '../shared/useLocalStorage';
import Slider from '@material-ui/core/Slider';
import withStyles from '@material-ui/core/styles/withStyles';
import dashboardStyle from '../assets/jss/styles/components/dashboardStyle';
import ExpandLessIcon from '@material-ui/icons/Remove';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '../components/Publications/Table/Table';
import GridOn from '@material-ui/icons/GridOn';
import ViewList from '@material-ui/icons/ViewList';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FilterList from '@material-ui/icons/FilterList';
import Slide from '@material-ui/core/Slide';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import { FormControl, InputLabel, OutlinedInput } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Helmet from 'react-helmet';
import Constants from '../../static/Constants';
import ContentLoader from 'react-content-loader';
import FilterText from '../components/FilterText';
import projectStyles, { useStyles } from '../styles/projects';
import { useTranslation } from 'react-i18next';
import ProjectsSearch from '../components/ProjectsSearch.jsx';
import GridView from '../components/publications/GridView';
import getHeaderLInks from '../shared/headerLinks.js';
import { create } from 'apisauce';
import { ThreeDots } from 'react-loader-spinner';
import { Container } from 'layouts';
import styles from '../styles/projects';
import NoProjects from '../components/NoProjects';

const { costSlider } = styles;

const apiSauceInstance = create({ baseURL: Constants.apiUrl });

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

function useMounted() {
  const [isMounted, setIsMounted] = useState(false);
  React.useEffect(() => {
    setIsMounted(true);
  }, []);
  return isMounted;
}

const {
  buttonSubmit,
  buttonClear,
  buttonApply,
  FilterHeading,
  MobileFilter,
  costRangeInput,
  filterItem,
  clearSection,
  GridSkeletonWrapper,
  costRangeInputMobile,
} = projectStyles;

const Skeleton = props => (
  <ContentLoader
    height={250}
    width={320}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width="320" height="250" />
  </ContentLoader>
);

const getQueryStringParams = query => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((acc, param) => {
          let [keyValue, ...values] = param.split('::');
          let [key, value] = keyValue.split('=');
          if (value) {
            const paramValues = [value, ...values];
            acc[key] = (paramValues || []).map(value =>
              decodeURIComponent(value.replace(/\+/g, ' '))
            );
            return acc;
          }
          acc[key] = undefined;
          return [];
        }, {})
    : {};
};

const Publications = ({ ...props }) => {
  const { t, i18n } = useTranslation();
  const { pageContext } = props;

  const data = pageContext.pageData ? pageContext.pageData : null;

  if (!data) {
    return;
  }

  const helmetLink = `${Constants.appUrl}/projects`;
  const { location, navigate } = props;

  const programLink = pageContext.programUrl;

  const headerLinks = getHeaderLInks('Publications', programLink, data.noLinks);

  let logos = pageContext.logos || [];
  const dataSectionRef = useRef(null);

  const useless = 0;
  useEffect(() => {
    // setCostRange(costRange);
    setLoadMapViewData(loadMapViewData);
    setFavoriteProjects(favoriteProjects);
  }, [useless]);
  const { programName, screenName } = (data || {}).node || {};
  const { pageHeader, headerLogo, headerLogoScroll } = (data || {}).node || {};
  const defaultProjectsForMapView = 10;

  const styleClasses = useStyles();
  const [filteredPublications, setFilteredPublications] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState(
    getQueryStringParams(location.search || '')
  );
  const theme = useTheme();
  const [costRange, setCostRange] = useState([0, 0]);
  const [countries, setCountries] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [investmentTypes, setInvestmentTypes] = useState([]);
  const [sezCount, setSezCount] = useState(0);
  const [sdg, setSdg] = useState([]);
  const [source, setSource] = useState([]);
  const [publicationsView, setPublicationsView] = useLocalStorage(
    'publicationsView',
    'grid',
    null,
    true
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [filterTab, setFilterTab] = useState(0);
  const [loadMapViewData, setLoadMapViewData] = useState(false);
  const [isProcessing, setIsProcessing] = useState(true);
  const [isFiltersProcessing, setIsFiltersProcessing] = useState(true);
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [filterMasterData, setFilterMasterData] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(
    publicationsView === 'map' ? defaultProjectsForMapView : 10
  );
  const [sortBy, setSortBy] = useState('projectTitle');
  const [sortOrder, setSortOrder] = useState('asc');
  const [isCostRangeUpdated, setCostRangeUpdated] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [clearFilterSection, setClearFilterSection] = useState(false);
  const [filterSectionExpand, setFilterSectionExpand] = useState('');
  const [favoriteProjects, setFavoriteProjects] = useLocalStorage(
    `${data.node.programCode ? data.node.programCode : ''}Favorites`,
    []
  );
  const [isFavoritesView, setisFavoritesView] = useState(false);
  const [totalInvestmentCostApplied, setTotalInvestmentCostApplied] = useState(
    false
  );
  const [publicationTypes, setPublicationTypes] = useState([]);
  const [publicationTypeApplied, setPublicationTypeApplied] = useState(false);
  const isMounted = useMounted(); // Check if mounted or not

  //Apply Button functionality
  const [sectorApplied, setSectorApplied] = useState(false);
  const [countryApplied, setCountryApplied] = useState(false);

  const publicationType = [
    {
      id: 1,
      text: 'Report',
    },
    {
      id: 2,
      text: 'Journal/Newspaper Article',
    },
    {
      id: 3,
      text: 'Policy document',
    },
    {
      id: 4,
      text: 'Promotional flyer/brochure',
    },
    {
      id: 5,
      text: 'Dataset',
    },
    {
      id: 6,
      text: 'Video',
    },
    {
      id: 7,
      text: 'Other',
    },
  ];

  useEffect(() => {
    // if (!filterMasterData.length > 0) {
    setIsFiltersProcessing(true);
    apiSauceInstance
      .get(Constants.getPublicationFilterMaster + `/${i18n.language}`)
      .then(filterCriteria => {
        if (filterCriteria && filterCriteria.data) {
          setFilterMasterData({
            ...filterCriteria.data,
            MediaType: publicationType,
          });
          let range = [
            Number(filterCriteria?.data?.YEAR?.min) || 0,
            Number(filterCriteria?.data?.YEAR?.max) || 9999,
          ];
          setCostRange(range);
          setIsFiltersProcessing(false);
        } else if (filterCriteria && !filterCriteria.ok) {
          setIsFiltersProcessing(false);
        }
      })
      .catch(error => {
        if (error) {
          setIsFiltersProcessing(false);
        }
      });
    // }
  }, [i18n.language]);

  const getVerifiedPublication = filterParams => {
    setIsProcessing(true);
    apiSauceInstance
      .post(Constants.getVerifiedPublications, filterParams)
      .then(projects => {
        if (projects && projects.ok) {
          setSezCount(projects.data.total);
          setFilteredPublications(projects.data.items || []);
          setIsProcessing(false);
        } else {
          setIsProcessing(false);
        }
      })
      .catch(error => {
        if (error) {
          setFilteredPublications([]);
          setSezCount(0);
          setIsProcessing(false);
        }
      });
  };

  useEffect(() => {
    setFilteredPublications([]);
    // setSearchCriteria(getQueryStringParams(location.search || ''));
  }, [location.search]);

  useEffect(() => {
    if (isMounted && filterMasterData) {
      setIsProcessing(true);
      let qs = getQueryStringParams(location.search || '');
      setSearchCriteria(qs);
      const filterParams = prepareFilterMasterParams(qs);
      getVerifiedPublication(filterParams);
      setFilterValues(qs);
    }
  }, [
    filterMasterData,
    location.search,
    // searchCriteria,
    sortBy,
    sortOrder,
    pageSize,
    pageNumber,
  ]);

  useEffect(() => {
    if (clearFilterSection) {
      setClearFilterSection(false);
      filterClick('filter')();
    }
  }, [clearFilterSection]);

  const filterClick = (action, inputQuery = null) => () => {
    let params = {};
    let queryString = '';
    if (action === 'filter' || action === 'clearSearch') {
      params = {
        search: searchString && action === 'filter' ? searchString : '',
        Country: countries ? countries : [],
        Sector: sectors ? sectors : [],
        Year: isCostRangeUpdated ? costRange.map(String) : null,
        MediaType: publicationTypes ? publicationTypes : [],
      };
      Object.keys(params).forEach(key => {
        if (!params[key] || params[key].length === 0) {
          delete params[key];
        }
      });

      queryString =
        '?' +
        Object.keys(params)
          .map(key =>
            key === 'search'
              ? `${key}=${params[key]}`
              : `${key}=${params[key].reduce((acc, value) => {
                  return acc === '' ? `${acc}${value}` : `${acc}::${value}`;
                }, '')}`
          )
          .join('&');
      setOpenDialog(false);
    } else {
      setCostRange([
        ((filterMasterData || {}).YEAR || {}).min || 0,
        ((filterMasterData || {}).YEAR || {}).max || 999999,
      ]);
      setCountries([]);
      setSectors([]);
      setInvestmentTypes([]);
      setSdg([]);
      setCostRangeUpdated(false);
      setSource([]);
      setSearchString('');
      setisFavoritesView(false);
      setPageNumber(1);
      setPublicationTypes([]);
    }
    setPageNumber(1);
    setPageSize(publicationsView === 'map' ? defaultProjectsForMapView : 10);
    navigate(`${location.pathname}${inputQuery || queryString}`, {}).then(
      () => {
        if (dataSectionRef.current) {
          dataSectionRef.current.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }
    );
  };

  const prepareFilterMasterParams = params => {
    let filters = [];
    let filterMasterParams = {};
    if (params) {
      if (Object.keys(params).find(key => key === 'Favorites')) {
        const val = {
          Name: 'Favorites',
          Value: JSON.parse(
            localStorage.getItem(
              `${data.node.programCode ? data.node.programCode : ''}Favorites`
            )
          ),
        };
        filters.push(val);
      } else {
        Object.keys(params).map(function(key) {
          let val = {};
          if (key === 'search') {
            val = {
              Name: key,
              Value: (params[key] || [''])[0],
            };
          } else {
            if (params[key].length > 1) {
              val = {
                Name: key === 'InvestmentType' ? 'PROJECTOPPORTUNITYTYPE' : key,
                Value: params[key].map(Number),
              };
            } else if (params[key].length > 0) {
              val = {
                Name: key === 'InvestmentType' ? 'PROJECTOPPORTUNITYTYPE' : key,
                Value: [parseInt(params[key])],
              };
            }
          }
          filters.push(val);
        });
      }

      filterMasterParams = {
        PageNumber: pageNumber,
        PageSize: pageSize,
        SortBy: sortBy,
        SortOrder: sortOrder,
        Filters: filters,
      };
    }
    return filterMasterParams;
  };

  const setFilterValues = params => {
    const filterMap = {
      Country: setCountries,
      TotalInvestmentCost: setCostRange,
      Year: setCostRange,
      Sector: setSectors,
      updated: () => {},
      InvestmentType: setInvestmentTypes,
      sdg: setSdg,
      search: setSearchString,
      Source: setSource,
      MediaType: setPublicationTypes,
    };

    if (Object.keys(params).find(key => key === 'Favorites')) {
      setisFavoritesView(true);
      return;
    } else {
      setisFavoritesView(false);
    }

    Object.keys(params).map(key => {
      const setFilter = filterMap[key] || (() => {});
      key === 'search'
        ? setFilter((params[key] || [''])[0])
        : setFilter(params[key].filter(e => e != '').map(val => parseInt(val)));

      if (key === 'Year') {
        setCostRangeUpdated(true);
      }
    });
  };

  const updateStateElement = (
    stateCollection,
    updateState,
    updateChange
  ) => value => () => {
    const collection = stateCollection || [];

    const currentIndex = (collection || []).indexOf(value);
    const updatedState =
      currentIndex === -1
        ? [...collection, value]
        : collection
            .slice(0, currentIndex)
            .concat(collection.slice(currentIndex + 1));

    updateState(updatedState);
    updateChange(true);
  };

  const handleCountriesChange = updateStateElement(
    countries,
    setCountries,
    setCountryApplied
  );

  const updateCostRange = (event, value) => {
    setCostRangeUpdated(true);
    setCostRange(value);
    setTotalInvestmentCostApplied(true);
  };

  const handleSectorsChange = updateStateElement(
    sectors,
    setSectors,
    setSectorApplied
  );

  const changeProjectView = view => () => {
    setPageNumber(1);
    //setPageSize(view === 'map' ? defaultProjectsForMapView : 10);
    setPublicationsView(view);
  };

  const updateFilter = setFilter => value => {
    setFilteredPublications([]);
    setFilter && setFilter(value);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleFilterTabChange = (event, newValue) => {
    setFilterTab(newValue);
  };

  const handlePublicationTypeChange = updateStateElement(
    publicationTypes,
    setPublicationTypes,
    setPublicationTypeApplied
  );

  // Search Functionality
  const onPublicationsSearch = action => {
    action
      ? searchString.length === 0
        ? location.href &&
          location.href.indexOf('?') > 0 &&
          filterClick('clearSearch')()
        : searchString.length > 2 && filterClick('filter')()
      : location.href &&
        location.href.indexOf('?') > 0 &&
        filterClick('clearSearch')();
  };

  // Filter Expansion panel

  const handleExpansionClick = panel => (event, isExpanded) => {
    setFilterSectionExpand(isExpanded ? panel : false);
  };

  return (
    <Layout
      programLink={programLink}
      title={t('publications_title')}
      content={[t('publications_subtitle')]}
      headerLinks={headerLinks}
      logos={logos}
      pageHeader={pageHeader}
      headerLogo={headerLogo}
      headerLogoScroll={headerLogoScroll}
      imageName={'bg-publication.jpg'}
      visitedLinks={[{ url: '/', key: 'home', name: 'Home' }]}
      currentPage={{ key: 'publications' }}
      screenName={screenName}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('Publications')}</title>
        <link rel="canonical" href={helmetLink} />
      </Helmet>

      <div className="container">
        <GridContainer className="projects-container">
          <GridItem md={4}>
            <ProjectsSearch
              onProjectsSearch={onPublicationsSearch}
              searchString={searchString}
              setSearchString={setSearchString}
              placeholder={t('search_publications')}
            />
            {!mobileScreen ? (
              <>
                <div className="filters-container">
                  <ExpansionPanel
                    expanded={filterSectionExpand === 'country'}
                    onChange={handleExpansionClick('country')}
                    className={styleClasses.panel}
                  >
                    <ExpansionPanelSummary
                      className={styleClasses.summary}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={styleClasses.heading}>
                        {t('Country')}
                        {countries.length ? (
                          <ClearFilterSection
                            handleClick={() => {
                              setCountries([]);
                              setClearFilterSection(true);
                            }}
                          />
                        ) : (
                          ''
                        )}
                        {countryApplied ? (
                          <ApplyFilterSection
                            handleClick={filterClick('filter')}
                            updateElement={setCountryApplied}
                          />
                        ) : (
                          ''
                        )}
                      </Typography>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails className={styleClasses.contents}>
                      <List>
                        {filterMasterData &&
                          (filterMasterData.COUNTRY || []).map(value => {
                            return (
                              <ListItem key={value.countryID}>
                                <Checkbox
                                  color="primary"
                                  className={styleClasses.checkbox}
                                  edge="start"
                                  checked={
                                    countries.indexOf(value.countryID) !== -1
                                  }
                                  onChange={handleCountriesChange(
                                    value.countryID
                                  )}
                                  tabIndex={-1}
                                  disableRipple
                                  value={value.countryID}
                                ></Checkbox>
                                <p css={filterItem}>{value.countryName}</p>
                              </ListItem>
                            );
                          })}
                      </List>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>

                  <ExpansionPanel
                    expanded={filterSectionExpand === 'sector'}
                    onChange={handleExpansionClick('sector')}
                    className={styleClasses.panel}
                  >
                    <ExpansionPanelSummary
                      className={styleClasses.summary}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={styleClasses.heading}>
                        {t('Sector')}
                        {sectors.length ? (
                          <ClearFilterSection
                            handleClick={() => {
                              setSectors([]);
                              setClearFilterSection(true);
                            }}
                          />
                        ) : (
                          ''
                        )}
                        {sectorApplied ? (
                          <ApplyFilterSection
                            handleClick={filterClick('filter')}
                            updateElement={setSectorApplied}
                          />
                        ) : (
                          ''
                        )}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={styleClasses.contents}>
                      <List>
                        {filterMasterData &&
                          (filterMasterData.SECTOR || []).map(value => {
                            return (
                              <ListItem key={value.companySectorCodeID}>
                                <Checkbox
                                  color="primary"
                                  className={styleClasses.checkbox}
                                  edge="start"
                                  checked={
                                    sectors.indexOf(
                                      value.companySectorCodeID
                                    ) !== -1
                                  }
                                  onChange={handleSectorsChange(
                                    value.companySectorCodeID
                                  )}
                                  tabIndex={-1}
                                  disableRipple
                                  value={value.companySectorCodeID}
                                ></Checkbox>
                                <Tooltip
                                  title={value.description}
                                  placement="top-start"
                                >
                                  <p css={filterItem}>{value.description}</p>
                                </Tooltip>
                              </ListItem>
                            );
                          })}
                      </List>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>

                  <ExpansionPanel
                    expanded={filterSectionExpand === 'year'}
                    onChange={handleExpansionClick('year')}
                    className={styleClasses.panel}
                  >
                    <ExpansionPanelSummary
                      className={styleClasses.summary}
                      expandIcon={
                        filterSectionExpand === 'year' ? (
                          <ExpandLessIcon className={styleClasses.expand} />
                        ) : (
                          <ExpandMoreIcon className={styleClasses.expand} />
                        )
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography
                        className={`${styleClasses.heading} ${
                          filterSectionExpand === 'year'
                            ? styleClasses.headingExpanded
                            : ''
                        } `}
                      >
                        {t('year')}
                        {isCostRangeUpdated ? (
                          <ClearFilterSection
                            handleClick={() => {
                              setCostRangeUpdated(false);
                              setCostRange([
                                filterMasterData.YEAR.min || 0,
                                filterMasterData.YEAR.max || 999999,
                              ]);
                              setClearFilterSection(true);
                            }}
                          />
                        ) : (
                          ''
                        )}
                        {totalInvestmentCostApplied &&
                        costRange[1] <= (filterMasterData.YEAR.max || 0) &&
                        costRange[1] >= (filterMasterData.YEAR.min || 0) &&
                        costRange[0] >= (filterMasterData.YEAR.min || 0) &&
                        costRange[0] <= (filterMasterData.YEAR.max || 0) &&
                        costRange[1] >= costRange[0] ? (
                          <ApplyFilterSection
                            handleClick={filterClick('filter')}
                            updateElement={setTotalInvestmentCostApplied}
                          />
                        ) : (
                          ''
                        )}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                      className={styleClasses.costContents}
                    >
                      <div>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          css={costRangeInput}
                        >
                          <InputLabel htmlFor="CostMin">Min</InputLabel>
                          <OutlinedInput
                            id="CostMin"
                            value={costRange[0]}
                            onFocus={e => e.target.select()}
                            disabled={true}
                            // onChange={e => {
                            //   setCostRangeUpdated(true);
                            //   setCostRange([
                            //     parseInt(e.target.value.replace(/\D/g, '')) ||
                            //     0,
                            //     costRange[1],
                            //   ]);
                            //   setTotalInvestmentCostApplied(true);
                            // }}
                            endAdornment={
                              <InputAdornment position="end"></InputAdornment>
                            }
                            labelWidth={30}
                            error={
                              costRange[0] <
                                (((filterMasterData || {}).YEAR || {}).min ||
                                  0) ||
                              costRange[0] >
                                (((filterMasterData || {}).YEAR || {}).max ||
                                  999999)
                            }
                          />
                        </FormControl>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          css={costRangeInputMobile}
                        >
                          <InputLabel htmlFor="CostMax">Max</InputLabel>
                          <OutlinedInput
                            id="CostMax"
                            value={costRange[1]}
                            onFocus={e => e.target.select()}
                            disabled={true}
                            // onChange={e => {
                            //   setCostRangeUpdated(true);
                            //   setCostRange([
                            //     costRange[0],
                            //     parseInt(e.target.value.replace(/\D/g, '')) ||
                            //     0,
                            //   ]);
                            //   setTotalInvestmentCostApplied(true);
                            // }}
                            endAdornment={
                              <InputAdornment position="end"></InputAdornment>
                            }
                            labelWidth={30}
                            error={
                              costRange[1] <
                                (((filterMasterData || {}).YEAR || {}).min ||
                                  0) ||
                              costRange[1] >
                                (((filterMasterData || {}).YEAR || {}).max ||
                                  999999)
                            }
                          />
                        </FormControl>
                      </div>
                      {filterMasterData &&
                      filterMasterData.YEAR &&
                      filterMasterData.YEAR.min !== null &&
                      filterMasterData.YEAR.max !== null ? (
                        <Slider
                          value={costRange}
                          min={Number(filterMasterData.YEAR.min)}
                          css={costSlider}
                          valueLabelDisplay="auto"
                          max={Number(filterMasterData.YEAR.max)}
                          onChange={updateCostRange}
                        />
                      ) : null}
                    </ExpansionPanelDetails>
                  </ExpansionPanel>

                  <ExpansionPanel
                    expanded={filterSectionExpand === 'publicationType'}
                    onChange={handleExpansionClick('publicationType')}
                    className={styleClasses.panel}
                  >
                    <ExpansionPanelSummary
                      className={styleClasses.summary}
                      expandIcon={
                        filterSectionExpand === 'publicationType' ? (
                          <ExpandLessIcon className={styleClasses.expand} />
                        ) : (
                          <ExpandMoreIcon className={styleClasses.expand} />
                        )
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography
                        className={`${styleClasses.heading} ${
                          filterSectionExpand === 'publicationType'
                            ? styleClasses.headingExpanded
                            : ''
                        } `}
                      >
                        {t('media_type')}
                        {publicationTypes.length ? (
                          <ClearFilterSection
                            handleClick={() => {
                              setPublicationTypes([]);
                              setClearFilterSection(true);
                            }}
                          />
                        ) : (
                          ''
                        )}
                        {publicationTypeApplied ? (
                          <ApplyFilterSection
                            handleClick={filterClick('filter')}
                            updateElement={setPublicationTypeApplied}
                          />
                        ) : (
                          ''
                        )}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={styleClasses.contents}>
                      <List>
                        {filterMasterData &&
                          (filterMasterData.MEDIATYPE || []).map(val => {
                            return (
                              <ListItem key={val.value}>
                                <Checkbox
                                  color="primary"
                                  className={styleClasses.checkbox}
                                  edge="start"
                                  checked={
                                    publicationTypes.indexOf(val.value) !== -1
                                  }
                                  onChange={handlePublicationTypeChange(
                                    val.value
                                  )}
                                  tabIndex={-1}
                                  disableRipple
                                  value={val.value}
                                ></Checkbox>
                                <Tooltip title={val.name} placement="top-start">
                                  <p css={filterItem}>{val.name}</p>
                                </Tooltip>
                              </ListItem>
                            );
                          })}
                      </List>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
                <div className="filter-actions">
                  <button
                    onClick={filterClick('filter')}
                    className="submit-button"
                    disabled={
                      !countries.length &&
                      !sectors.length &&
                      !sdg.length &&
                      !investmentTypes.length &&
                      searchString.length < 3 &&
                      !isCostRangeUpdated &&
                      !source.length &&
                      !publicationTypes.length
                    }
                  >
                    {t('Submit')}
                  </button>
                  {
                    {
                      false: null,
                      true: (
                        <button
                          onClick={filterClick('clear')}
                          className="submit-button submit-clear"
                        >
                          {t('Clear')}
                        </button>
                      ),
                    }[location.href && location.href.indexOf('?') > 0]
                  }
                </div>
              </>
            ) : (
              <>
                <Button color="inherit" onClick={() => setOpenDialog(true)}>
                  <FilterList />
                  <FilterHeading>{t('Filter')}</FilterHeading>
                </Button>
                <Dialog
                  fullScreen
                  open={openDialog}
                  onClose={handleDialogClose}
                  TransitionComponent={Transition}
                  css={MobileFilter}
                >
                  <AppBar className={styleClasses.appBar}>
                    <Toolbar>
                      <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleDialogClose}
                        aria-label="close"
                      >
                        <CloseIcon />
                      </IconButton>
                      <Typography variant="h6" className={styleClasses.title}>
                        {t('Filter')}
                      </Typography>
                      <Button
                        color="inherit"
                        onClick={filterClick('filter')}
                        disabled={
                          !countries.length &&
                          !sectors.length &&
                          !sdg.length &&
                          !investmentTypes.length &&
                          searchString.length < 3 &&
                          !isCostRangeUpdated
                        }
                      >
                        {t('Submit')}
                      </Button>
                    </Toolbar>
                  </AppBar>
                  <div className={styleClasses.root}>
                    <Tabs
                      orientation="vertical"
                      variant="scrollable"
                      value={filterTab}
                      onChange={handleFilterTabChange}
                      aria-label="Vertical tabs example"
                      className={styleClasses.tabs}
                    >
                      <Tab
                        className={styleClasses.tabItem}
                        label={t('Country')}
                        {...filterTabs(0)}
                      />
                      <Tab
                        className={styleClasses.tabItem}
                        label={t('Sector')}
                        {...filterTabs(1)}
                      />
                    </Tabs>

                    <TabPanel
                      value={filterTab}
                      index={0}
                      className={styleClasses.tabsPanel}
                    >
                      <div className={styleClasses.contentsMobile}>
                        <List>
                          {filterMasterData &&
                            filterMasterData.COUNTRY &&
                            filterMasterData.COUNTRY.map(value => {
                              return (
                                <ListItem key={value.countryID}>
                                  <Checkbox
                                    color="primary"
                                    className={styleClasses.checkbox}
                                    edge="start"
                                    checked={
                                      countries.indexOf(value.countryID) !== -1
                                    }
                                    onChange={handleCountriesChange(
                                      value.countryID
                                    )}
                                    tabIndex={-1}
                                    disableRipple
                                    value={value.countryID}
                                  ></Checkbox>
                                  <p>{value.countryName}</p>
                                </ListItem>
                              );
                            })}
                        </List>
                      </div>
                    </TabPanel>

                    <TabPanel
                      value={filterTab}
                      index={1}
                      className={styleClasses.tabsPanel}
                    >
                      <div className={styleClasses.contentsMobile}>
                        <List>
                          {filterMasterData &&
                            (filterMasterData.SECTOR || []).map(value => {
                              return (
                                <ListItem key={value.companySectorCodeID}>
                                  <Checkbox
                                    color="primary"
                                    className={styleClasses.checkbox}
                                    edge="start"
                                    checked={
                                      sectors.indexOf(
                                        value.companySectorCodeID
                                      ) !== -1
                                    }
                                    onChange={handleSectorsChange(
                                      value.companySectorCodeID
                                    )}
                                    tabIndex={-1}
                                    disableRipple
                                    value={value.companySectorCodeID}
                                  ></Checkbox>
                                  <p css={filterItem}>{value.description}</p>
                                </ListItem>
                              );
                            })}
                        </List>
                      </div>
                    </TabPanel>
                  </div>
                </Dialog>
                {
                  {
                    false: null,
                    true: (
                      <button
                        onClick={filterClick('clear')}
                        css={[buttonSubmit, buttonClear]}
                        color="inherit"
                      >
                        {t('Clear')}
                      </button>
                    ),
                  }[location.href && location.href.indexOf('?') > 0]
                }
              </>
            )}
          </GridItem>

          <GridItem md={8}>
            <GridContainer className="project-top">
              <GridItem md={7} xs={12} className="filter-text">
                {
                  {
                    true: null,
                    false:
                      sezCount > 0 || Object.keys(searchCriteria).length > 0 ? (
                        <FilterText
                          filterMasterData={filterMasterData}
                          searchCriteria={searchCriteria}
                          totalProjectCount={sezCount}
                          currentProjectCount={
                            (filteredPublications || {}).length || 0
                          }
                          location={location}
                          navigate={navigate}
                          filterClick={filterClick('clear')}
                          placeholderSingle="Publication"
                          placeholder="Publications"
                        />
                      ) : (
                        ''
                      ),
                  }[isProcessing]
                }
              </GridItem>

              <GridItem md={5} xs={12} className="project-view">
                <div ref={dataSectionRef}>
                  <Tooltip title={t('grid_view')} placement="top">
                    <IconButton
                      edge="start"
                      color={
                        publicationsView === 'grid' ? 'primary' : 'default'
                      }
                      onClick={changeProjectView('grid')}
                      aria-label="grid"
                    >
                      <GridOn />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={t('list_view')} placement="top">
                    <IconButton
                      edge="start"
                      color={
                        publicationsView === 'list' ? 'primary' : 'default'
                      }
                      onClick={changeProjectView('list')}
                      aria-label="list"
                    >
                      <ViewList />
                    </IconButton>
                  </Tooltip>
                </div>
              </GridItem>
            </GridContainer>
            {
              {
                grid: (
                  <div>
                    <GridView
                      totalProjects={sezCount}
                      gridData={filteredPublications}
                      onPageNumberChange={updateFilter(setPageNumber)}
                      onPageSizeChange={updateFilter(setPageSize)}
                      fallback={
                        <Container width="100%">
                          <div className="three-dots-loader">
                            <ThreeDots
                              color="#5990ff"
                              height={100}
                              width={100}
                              timeout={10000}
                            />
                          </div>
                        </Container>
                      }
                      isLoading={isProcessing || isFiltersProcessing}
                      programLink={programLink}
                      programCode={programName}
                      pageNumber={pageNumber}
                      pageSize={pageSize}
                    />
                  </div>
                ),
                list: (
                  <div>
                    {sezCount ? (
                      <Table
                        tableHeaderColor="warning"
                        tableHead={[t('Sno'), t('Name'), t('Action')]}
                        tableData={filteredPublications}
                        totalProjects={sezCount}
                        pageNumber={pageNumber}
                        pageSize={pageSize}
                        onPageNumberChange={updateFilter(setPageNumber)}
                        onPageSizeChange={updateFilter(setPageSize)}
                        onSortByChange={updateFilter(setSortBy)}
                        onSortOrderChange={updateFilter(setSortOrder)}
                        isFavoritesView={isFavoritesView}
                        fallback={
                          <div style={{ lineHeight: 3 }}>
                            {[1, 2, 3, 4, 5, 6].map(num => (
                              <Skeleton count={10} key={num} height={10} />
                            ))}
                          </div>
                        }
                        isLoading={isProcessing || isFiltersProcessing}
                        programLink={programLink}
                        programCode={
                          data.node.programCode ? data.node.programCode : ''
                        }
                        handleFavoriteProjects={() => {}}
                        favoriteProjects={favoriteProjects}
                      />
                    ) : isProcessing || isFiltersProcessing ? (
                      <Container width="100%">
                        <div className="three-dots-loader">
                          <ThreeDots
                            color="#5990ff"
                            height={100}
                            width={100}
                            timeout={10000}
                          />
                        </div>
                      </Container>
                    ) : (
                      <NoProjects data="Publications" />
                    )}
                  </div>
                ),
              }[publicationsView]
            }
          </GridItem>
        </GridContainer>
      </div>
    </Layout>
  );
};

export default withStyles(dashboardStyle)(Publications);

const GridSkeleton = () => {
  const gridArray = Array.from(Array(2).keys());
  return (
    <GridSkeletonWrapper>
      {gridArray.map(e => {
        return (
          <GridItem xs={12} sm={12} md={6} key={e}>
            <Skeleton height={200} />
          </GridItem>
        );
      })}
    </GridSkeletonWrapper>
  );
};

const ClearFilterSection = ({ handleClick }) => {
  return (
    <FormControlLabel
      aria-label="Acknowledge"
      css={clearSection}
      onClick={event => event.stopPropagation()}
      onFocus={event => event.stopPropagation()}
      control={
        <IconButton
          size="small"
          color="inherit"
          onClick={handleClick}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      }
    />
  );
};

const ApplyFilterSection = ({ handleClick, updateElement }) => {
  const { t } = useTranslation();
  return (
    <FormControlLabel
      aria-label="Acknowledge"
      css={clearSection}
      onClick={() => (updateElement ? updateElement(false) : '')}
      onFocus={event => event.stopPropagation()}
      control={
        <button css={buttonApply} onClick={handleClick}>
          {t('apply')}
        </button>
      }
    />
  );
};

ClearFilterSection.propTypes = {
  handleClick: PropTypes.func,
};

ApplyFilterSection.propTypes = {
  handleClick: PropTypes.func,
  updateElement: PropTypes.func,
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

Publications.propTypes = {
  classes: PropTypes.any,
  location: PropTypes.object,
  navigate: PropTypes.func,
  pageContext: PropTypes.any,
};

function filterTabs(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
