import React from 'react';
import PropTypes from 'prop-types';
import GridContainer from '../Grid/GridContainer.jsx';
import GridItem from '../Grid/GridItem.jsx';
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../Table/TablePaginationActions';
import NoProjects from '../NoProjects';
import styles from './GridView.styles';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import ArrowRightAltIcon from '@material-ui/icons/ArrowDownward';
import IconButton from '@material-ui/core/IconButton';
import getStaticImageURL from '../../shared/getStaticImageURL.js';

const { TableGridPagination } = styles;

const GridView = ({ ...props }) => {
  const { t } = useTranslation();
  const {
    gridData,
    totalProjects,
    isLoading,
    fallback,
    pageNumber,
    pageSize,
    onPageNumberChange,
    onPageSizeChange,
  } = props;

  const handlePageNumberChange = (event, page) => {
    onPageNumberChange(page + 1);
  };

  const handleChangeRowsPerPage = event => {
    onPageSizeChange(parseInt(event.target.value, 10));
  };
  return isLoading ? (
    <div>{fallback}</div>
  ) : (
    <GridContainer>
      {gridData && gridData.length > 0
        ? [
            ...(gridData || [])
              //.slice(pageNumber * pageSize, pageNumber * pageSize + pageSize)
              .map((publication, key) => (
                <GridItem xs={12} sm={6} md={6} xl={6} key={key}>
                  <a
                    href={publication.fileUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Card className="project-card publication-card">
                      <CardActionArea>
                        <div className="publication-image">
                          <img
                            src={
                              publication.coverImage ||
                              // require('../../assets/img/article-projects-2.png') ||
                              getStaticImageURL('article-projects-2.png')
                            }
                            alt="pub-cover"
                          />
                        </div>
                        <div className="project-content">
                          <CardContent className="card-details">
                            <div className="description-container">
                              <div className="publication-title">
                                <Tooltip
                                  title={publication.title}
                                  placement="top-start"
                                >
                                  <h2>{publication.title}</h2>
                                </Tooltip>
                              </div>
                            </div>
                          </CardContent>
                          <CardActions className="project-action">
                            <IconButton>
                              <ArrowRightAltIcon />
                            </IconButton>
                          </CardActions>
                        </div>
                      </CardActionArea>
                    </Card>
                  </a>
                </GridItem>
              )),
            <GridContainer key="pager">
              <GridItem xs={12} sm={12} md={12}>
                <Table>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        css={TableGridPagination}
                        rowsPerPageOptions={[10, 20, 30]}
                        colSpan={3}
                        count={totalProjects}
                        rowsPerPage={pageSize}
                        page={pageNumber - 1}
                        SelectProps={{
                          inputProps: {
                            'aria-label': t('publication_per_page'),
                          },
                          native: true,
                        }}
                        labelRowsPerPage={t('publication_per_page')}
                        onChangePage={handlePageNumberChange}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </GridItem>
            </GridContainer>,
          ]
        : !isLoading &&
          gridData &&
          gridData.length == 0 && <NoProjects data="Publications" />}
    </GridContainer>
  );
};

GridView.propTypes = {
  gridData: PropTypes.any,
  fallback: PropTypes.any,
  totalProjects: PropTypes.number,
  onPageNumberChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  isLoading: PropTypes.bool,
  programLink: PropTypes.string,
  programCode: PropTypes.string,
  handleFavoriteProjects: PropTypes.func,
  favoriteProjects: PropTypes.array,
};

export default GridView;
