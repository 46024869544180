import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from './TablePaginationActions';
// core components
import tableStyle from '../../../assets/jss/styles/components/tableStyle';
import NoProjects from '../../NoProjects';
import { useTranslation } from 'react-i18next';

function descendingComparator(a, b, orderBy) {
  if (typeof a[orderBy] == 'string') {
    if (b[orderBy].toUpperCase() < a[orderBy].toUpperCase()) {
      return -1;
    }
    if (b[orderBy].toUpperCase() > a[orderBy].toUpperCase()) {
      return 1;
    }
    return 0;
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function CustomTable({ ...props }) {
  const { t } = useTranslation();
  const {
    classes,
    tableHead,
    tableData,
    tableHeaderColor,
    totalProjects,
    pageNumber,
    pageSize,
    onPageSizeChange,
    onPageNumberChange,
    isLoading,
    fallback,
  } = props;

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');

  const handleSortByChange = property => () => {
    const isAsc = orderBy === getNameByTitle(property) && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(getNameByTitle(property));
  };

  const handlePageNumberChange = (event, page) => {
    onPageNumberChange(page + 1);
  };

  const handleChangeRowsPerPage = event => {
    onPageSizeChange(parseInt(event.target.value, 10));
  };

  const openInNewTab = url => {
    if (typeof window !== 'undefined') {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    }
  };

  const getNameByTitle = title => {
    if (title) {
      if (title === 'Name') {
        return 'title';
      }
    }
  };

  return isLoading ? (
    <div>{fallback}</div>
  ) : (
    <div className={classes.tableResponsive}>
      {tableData && tableData.length > 0 ? (
        <Table className={classes.table}>
          {tableHead !== undefined ? (
            <TableHead className={classes[tableHeaderColor + 'TableHeader']}>
              <TableRow className={classes.tableHeadRow}>
                {(tableHead || []).map((name, key) => {
                  return (
                    <TableCell
                      className={
                        classes.tableCell + ' ' + classes.tableHeadCell
                      }
                      key={key}
                    >
                      {key === 1 ? (
                        <TableSortLabel
                          active={orderBy === name}
                          direction={order}
                          onClick={handleSortByChange(name)}
                        >
                          {name}
                        </TableSortLabel>
                      ) : (
                        name
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {(stableSort(tableData, getComparator(order, orderBy)) || [])
              // .slice(pageNumber * pageSize, pageNumber * pageSize + pageSize)
              .map((publication, key) => {
                return (
                  !publication.isSEZ && (
                    <TableRow
                      hover
                      style={{ cursor: 'pointer' }}
                      key={key}
                      className={classes.tableBodyRow}
                    >
                      <TableCell className={classes.tableCell}>
                        <Tooltip title={key + 1} placement="top-start">
                          <span>{key + 1}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Tooltip
                          title={publication.title}
                          placement="top-start"
                        >
                          <span>{publication.title}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Tooltip
                          title={t('click_download')}
                          placement="top-start"
                        >
                          <button
                            onClick={() => openInNewTab(publication.url)}
                            className={classes.buttonSubmit}
                          >
                            {t('download')}
                          </button>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  )
                );
              })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30, 40]}
                colSpan={4}
                count={totalProjects}
                rowsPerPage={[pageSize]}
                page={pageNumber - 1}
                SelectProps={{
                  inputProps: { 'aria-label': t('publication_per_page') },
                  native: true,
                }}
                labelRowsPerPage={t('publication_per_page')}
                onChangePage={handlePageNumberChange}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      ) : (
        <NoProjects data="Publication" />
      )}
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: 'gray',
};

CustomTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    'warning',
    'primary',
    'danger',
    'success',
    'info',
    'rose',
    'gray',
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.object),
  onSortByChange: PropTypes.any,
  onSortOrderChange: PropTypes.any,
  onPageNumberChange: PropTypes.any,
  onPageSizeChange: PropTypes.any,
  totalProjects: PropTypes.any,
  isLoading: PropTypes.bool,
  fallback: PropTypes.element,
  programLink: PropTypes.string,
  programCode: PropTypes.string,
  handleFavoriteProjects: PropTypes.func,
  favoriteProjects: PropTypes.array,
  isFavoritesView: PropTypes.bool,
};

export default withStyles(tableStyle)(CustomTable);
